import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import React from "react"

const ReCaptchaProvider = ({ children }) => (
  <GoogleReCaptchaProvider reCaptchaKey="6LfhF0QkAAAAAGdA6F4jSxtp2C4pZYSMQmya0bxl">
    {children}
  </GoogleReCaptchaProvider>
)

export default ReCaptchaProvider
