/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import "./layout.css"

import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Footer from "./footer"
import Header from "./header"
import ReCaptchaProvider from "./re-captcha-provider"

const Layout = ({ children, navigation }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ReCaptchaProvider>
      <Header navigation={navigation} />
      <div
        style={{
          margin: `0 auto`,
        }}
      >
        <main>{children}</main>
      </div>

      <Footer />
      <noscript>
        <img
          src="https://ws.zoominfo.com/pixel/620e93a614de72001cc2aa77"
          width="1"
          height="1"
          style={{ display: "none" }}
        />
      </noscript>
    </ReCaptchaProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
